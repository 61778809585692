var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f6c59f229be75974377cc3181268795917688a91"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://28fc65492a154c819cb56b7b91cd736f@o169036.ingest.sentry.io/6156686',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  environment: process.env.NEXT_ENV || 'production',
  disabled: process.env.NODE_ENV === 'development',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    'Failed to fetch',
    'Load failed',
    'No error message',
    'NotSupportedError',
    'Unexpected token',
    'NotAllowedError',
    'safari-web-extension://',
    'Can\'t find variable: pktAnnotationHighlighter',
    'HierarchyRequestError: Failed to execute \'appendChild\' on \'Node\': This node type does not support this method.',
    'Network Error',
    'The operation is insecure',
    /Blocked a frame with origin/,
    /Loading chunk \d+ failed/,
    /ReportingObserver \[(deprecation|csp-violation)\]/,
    /SecurityError: .*replaceState/
  ]
})
