import type { CSSProperties } from 'react'
import { memo, useMemo } from 'react'
import { ReactSVG } from 'react-svg'
import type common from '@pocake/note-common-styles/json/partials/variables.json'

const iconList = [
  'arrowback',
  'arrowup',
  'close',
  'copy',
  'create',
  'search',
  'help',
  'imageAdd',
  'image',
  'add',
  'upload',
  'quote',
  'link',
  'file',
  'code',
  'twitterFill',
  'facebookFill',
  'lineFill',
  'checkCircle',
  'alignCenter',
  'alignLeft',
  'alignRight',
  'alt',
  'bold',
  'check',
  'expandmore',
  'italic',
  'strike',
  'title',
  'trash',
  'underline',
  'resizeImageUp',
  'resizeImageDown',
  'h2',
  'h3',
  'more',
  'enFill',
  'enLine',
  'lock',
  'info',
  'keyboard',
  'bulletedList',
  'orderedList',
  'increaseIndent',
  'decreaseIndent',
  'separate',
  'drag',
  'arrowdropdown',
  'arrowleft',
  'arrowright',
  'warning',
  'undo',
  'redo',
  'ai',
  'ai_2',
  'lightbulb',
  'robot',
  'insert',
  'swap',
  'toc',
  'copyLink',
  'imageAlignLeft',
  'imageAlignCenter',
  'imageAlignRight',
  'x',
  'pin',
  'arrowForward'
] as const

export const iconLabel = new Map([
  ['arrowback', '戻る'],
  ['close', '閉じる'],
  ['copy', 'コピー'],
  ['search', '検索'],
  ['help', 'ヘルプ'],
  ['imageAdd', '画像を追加'],
  ['image', '画像'],
  ['add', '追加'],
  ['upload', 'アップロード '],
  ['quote', '引用'],
  ['link', 'リンク'],
  ['file', 'ファイルアップロード'],
  ['code', 'コード'],
  ['twitterFill', 'Twitter'],
  ['x', 'X（Twitter）'],
  ['facebookFill', 'Facebook'],
  ['lineFill', 'LINE'],
  ['checkCircle', ''],
  ['alignCenter', '中央寄せ'],
  ['alignLeft', '左寄せ'],
  ['alignRight', '右寄せ'],
  ['alt', '代替テキスト'],
  ['bold', '太字'],
  ['check', ''],
  ['expandmore', ''],
  ['italic', '斜体'],
  ['strike', '取り消し線'],
  ['title', 'タイトル'],
  ['trash', '削除'],
  ['underline', '下線'],
  ['resizeImageUp', '拡大'],
  ['resizeImageDown', '縮小'],
  ['h2', '大見出し'],
  ['h3', '小見出し'],
  ['more', 'その他'],
  ['enFill', '有料'],
  ['enLine', '有料'],
  ['lock', '非公開'],
  ['info', 'インフォメーション'],
  ['keyboard', 'キーボード'],
  ['bulletedList', '箇条書きリスト'],
  ['orderedList', '番号付きリスト'],
  ['increaseIndent', 'インデントを下げる'],
  ['decreaseIndent', 'インデントを上げる'],
  ['separate', '区切り線'],
  ['drag', 'ドラッグして移動'],
  ['arrowleft', '戻る'],
  ['drag', 'ドラッグして移動'],
  ['warning', '警告'],
  ['undo', '元に戻す'],
  ['redo', 'やり直し'],
  ['ai', 'AI'],
  ['ai_2', 'AI'],
  ['lightbulb', 'ヒント'],
  ['copyLink', 'リンクをコピー'],
  ['imageAlignLeft', '左寄せ'],
  ['imageAlignCenter', '中央寄せ'],
  ['imageAlignRight', '右寄せ']
])

export type IconType = (typeof iconList)[number]

const sizeList = {
  small: '16px',
  mediumSmall: '20px',
  medium: '24px',
  large: '32px'
} as const

export type Size = keyof typeof sizeList

export type Props = {
  type: IconType
  size: Size
  color?: keyof typeof common
  style?: CSSProperties
  className?: string
  ariaLabel?: string
  ariaHidden?: boolean
}

export const Icon = memo(function Icon(props: Props) {
  const { size, style, className } = props

  const mergedStyle = {
    ...style,
    width: sizeList[size],
    height: sizeList[size]
  }

  return (
    <span style={mergedStyle} className={`inline-block ${className}`}>
      <SVG {...props} />
    </span>
  )
})

const SVG = memo(function SVG(props: Props) {
  const {
    type,
    size,
    color = 'color-text-primary',
    ariaLabel,
    ariaHidden
  } = props

  const svgSize = useMemo(() => {
    return sizeList[size]
  }, [size])

  const svgProps = useMemo(() => {
    return {
      width: svgSize,
      height: svgSize,
      fill: `var(--${color})`
    }
  }, [svgSize, color])

  const label = useMemo(() => {
    if (ariaHidden) {
      return undefined
    }

    return ariaLabel ?? iconLabel.get(type) ?? type
  }, [ariaHidden, ariaLabel, type])

  if (iconList.includes(type) === false) {
    return null
  }

  return (
    <ReactSVG
      src={`/icons/${type}.svg`}
      wrapper="span"
      role="img"
      aria-label={label}
      aria-hidden={ariaHidden}
      beforeInjection={(svg) => {
        Object.entries(svgProps).forEach(([key, value]) => {
          if (typeof value === 'string') {
            svg.setAttribute(key, value)
          }
        })
      }}
    />
  )
})
