import { css } from 'styled-components'
import common from '@pocake/note-common-styles/json/partials/variables.json'

export const queries = {
  'min-360': `(max-width: ${common['size-breakpoint_sp_under'] - 1}px)`,
  'min-480': `(max-width: ${common['size-breakpoint_sp'] - 1}px)`,
  'min-768': `(max-width: ${common['size-breakpoint_tb'] - 1}px)`,
  'min-796': '(max-width: 796px)',
  'min-940': `(max-width: ${common['size-content'] - 1}px)`,
  'min-1024': '(max-width: 1024px)',
  'min-1280': `(max-width: ${common['size-breakpoint_large'] - 1}px)`,
  '480-768': `(min-width: ${common['size-breakpoint_sp']}px) and (max-width: ${common['size-breakpoint_tb']}px)`,
  '768-940': `(min-width: ${common['size-breakpoint_tb']}px) and (max-width: ${common['size-content']}px)`,
  '940-1280': `(min-width: ${common['size-content']}px) and (max-width: ${common['size-breakpoint_large']}px)`,
  '1280-1920': `(min-width: ${common['size-breakpoint_large']}px) and (max-width: ${common['size-breakpoint_xlarge']}px)`,
  '768-max': `(min-width: ${common['size-breakpoint_tb'] + 1}px)`,
  '940-max': `(min-width: ${common['size-content']}px)`,
  '1140-max': '(min-width: 1140px)',
  '1280-max': `(min-width: ${common['size-breakpoint_large']}px)`,
  'height-300-max': '(max-height: 300px)',
  'height-400-max': '(max-height: 400px)',
  'height-500-max': '(max-height: 500px)',
  'height-600-max': '(max-height: 600px)',
  'height-700-max': '(max-height: 700px)',
  'height-800-max': '(max-height: 800px)'
} as const

export const mediaQuery = (range: keyof typeof queries) => {
  return `@media only screen and ${queries[range]}`
}

export const tooltipAnimation = css`
  animation: tooltipAnimation 200ms forwards var(--easeInOutExpo) 200ms;

  @keyframes tooltipAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

export const AriaLabelTooltip = css`
  &[aria-label] {
    position: relative;
    ${mediaQuery('768-max')} {
      &:hover,
      &:focus {
        &:after {
          position: absolute;
          display: block;
          top: -8px;
          left: 50%;
          max-width: 200px;
          padding: 8px;
          border: 1px solid var(--color-border-weak);
          border-radius: 4px;
          background-color: var(--color-background-secondary);
          white-space: nowrap;
          color: var(--color-text-secondary);
          font-size: var(--font-size-xs);
          font-weight: normal;
          text-align: center;
          line-height: 1;
          content: attr(aria-label);
          transform: translate(-50%, -100%);
          opacity: 0;
          ${tooltipAnimation};
        }
      }
    }
  }
`
export const AriaLabelTooltipRight = css`
  &[aria-label] {
    position: relative;
    ${mediaQuery('768-max')} {
      &:hover {
        &:after {
          position: absolute;
          display: block;
          left: calc(100% + 8px);
          top: 50%;
          max-width: 200px;
          padding: 8px;
          border: 1px solid var(--color-border-weak);
          border-radius: 4px;
          background-color: var(--color-background-secondary);
          white-space: nowrap;
          color: var(--color-text-secondary);
          font-size: var(--font-size-xs);
          font-weight: normal;
          text-align: center;
          line-height: 1;
          content: attr(aria-label);
          transform: translateY(-50%);
          opacity: 0;
          ${tooltipAnimation};
        }
      }
    }
  }
`
