import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'
import type { Action } from 'hooks/useEditor/useAssistants'

export type ChatMessage = {
  role: 'user' | 'assistant'
  text: string
}

export type AssistantsState = {
  isActive: boolean
  isModifiedFromSelectedText: boolean
  isSelectorOpen: boolean
  abortController: AbortController
  inputText: string
  output: string
  selectedAction: Action | null
  isProcessing: boolean
  isStreaming: boolean
  shouldShowTemporaryError: boolean
  reachUsageLimit: boolean
  isWelcomeModalOpen: boolean
  isDiscardModalOpen: boolean
  isUpgradeModalOpen: boolean
  isPremiumUsageLimitModalOpen: boolean
  isIntroduceProModalOpen: boolean
  shouldActivateOnLoad: boolean
  conversationHistory: ChatMessage[]
}

type AssistantsContextValue = {
  assistantsState: AssistantsState
  temporaryError: boolean
  reachUsageLimit: boolean
  isBeginning: boolean
  isGenerated: boolean
  nextButtonDisabled: boolean
  shouldShowMaxLengthWarning: boolean
  shouldShowMaxLengthError: boolean
}

const initialAssistantsState: AssistantsState = {
  isActive: false,
  isModifiedFromSelectedText: false,
  isSelectorOpen: false,
  abortController: new AbortController(),
  inputText: '',
  output: '',
  selectedAction: null,
  isProcessing: false,
  isStreaming: false,
  shouldShowTemporaryError: false,
  reachUsageLimit: false,
  isWelcomeModalOpen: false,
  isDiscardModalOpen: false,
  isUpgradeModalOpen: false,
  isPremiumUsageLimitModalOpen: false,
  isIntroduceProModalOpen: false,
  shouldActivateOnLoad: false,
  conversationHistory: []
}

const initialContextValue: AssistantsContextValue = {
  assistantsState: initialAssistantsState,
  temporaryError: initialAssistantsState.shouldShowTemporaryError,
  reachUsageLimit: initialAssistantsState.reachUsageLimit,
  isBeginning: true,
  isGenerated: false,
  nextButtonDisabled: true,
  shouldShowMaxLengthWarning: false,
  shouldShowMaxLengthError: false
}

const AssistantsContext =
  createContext<AssistantsContextValue>(initialContextValue)

const SetAssistantsContext = createContext<
  Dispatch<SetStateAction<AssistantsState>>
>(() => {})

export const AssistantsProvider = ({ children }: PropsWithChildren) => {
  const [assistantsState, setAssistantsState] = useState<AssistantsState>(
    initialAssistantsState
  )

  const {
    shouldShowTemporaryError: temporaryError,
    reachUsageLimit,
    isProcessing,
    output,
    inputText,
    selectedAction
  } = assistantsState

  const isBeginning = isProcessing === false && output === ''
  const isGenerated = isProcessing === false && output !== ''
  const nextButtonDisabled = temporaryError || inputText === ''
  const shouldShowMaxLengthWarning = Boolean(
    selectedAction &&
      selectedAction.action_values.is_full_text &&
      inputText.length > selectedAction.action_values.max_length
  )
  const shouldShowMaxLengthError = Boolean(
    selectedAction &&
      selectedAction.action_values.is_full_text === false &&
      inputText.length > selectedAction.action_values.max_length
  )

  const contextValue: AssistantsContextValue = {
    assistantsState,
    temporaryError,
    reachUsageLimit,
    isBeginning,
    isGenerated,
    nextButtonDisabled,
    shouldShowMaxLengthWarning,
    shouldShowMaxLengthError
  }

  return (
    <AssistantsContext.Provider value={contextValue}>
      <SetAssistantsContext.Provider value={setAssistantsState}>
        {children}
      </SetAssistantsContext.Provider>
    </AssistantsContext.Provider>
  )
}

export const useAssistantsContext = (): AssistantsContextValue => {
  const context = useContext(AssistantsContext)
  if (context === null) {
    throw new Error(
      'useAssistantsContext must be used within a AssistantsProvider'
    )
  }
  return context
}

export const useSetAssistantsContext = (): Dispatch<
  SetStateAction<AssistantsState>
> => {
  const context = useContext(SetAssistantsContext)
  if (context === null) {
    throw new Error(
      'useSetAssistantsContext must be used within a AssistantsProvider'
    )
  }
  return context
}
